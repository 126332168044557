import process from 'process';

import Error404Main from '@tb-core/components/container/error404';
import GlobalHeader from '@tb-core/components/container/headers/global-header';
import PageProvider, {
    PageContextProps
} from '@tb-core/components/context/webpage';
import WebpageLayout from '@tb-core/components/layout/webpage';
import DocumentHeadRegion from '@tb-core/components/regions/document-head';
import FooterRegion from '@tb-core/components/regions/footer';
import LeftAsideRegion from '@tb-core/components/regions/left-aside';
import LoyaltyAsideRegion from '@tb-core/components/regions/loyalty-aside';
import TopBody from '@tb-core/components/regions/top-body';
import getStaticPageData from '@tb-core/next/static-props-helpers/get-static-page-data';

const Error404 = (props: PageContextProps) => (
    <>
        {props.content && (
            <PageProvider {...props}>
                <WebpageLayout
                    regions={{
                        footer: <FooterRegion />,
                        head: <DocumentHeadRegion />,
                        header: <GlobalHeader />,
                        leftAside: <LeftAsideRegion />,
                        main: <Error404Main />,
                        rightAside: <LoyaltyAsideRegion />,
                        topBody: <TopBody />
                    }}
                />
            </PageProvider>
        )}
    </>
);

export async function getStaticProps() {
    const isStageProd =
        ['staging', 'production'].indexOf(process.env.NODE_ENV) >= 0;
    const pageData = await getStaticPageData(
        isStageProd,
        '/_static/web/data/404.webpage.json'
    );

    return {
        props: pageData
    };
}

export default Error404;
