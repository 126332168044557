import ResponsiveImage, {
    ResponsiveImageProps
} from '@tb-core/components/composites/responsive-image';
import BrandedLinkButton from '@tb-core/components/styled/links/branded-link-button';
import { ThemableLinkButtonProps } from '@tb-core/components/styled/links/themable-link-button';
import { entriesByTypename } from '@tb-core/helpers/content';
import usePageContext from '@tb-core/hooks/use-page-context';

import styles from './styles.module.scss';

interface Error404ContentProps {
    image?: ResponsiveImageProps;
    linkButton?: ThemableLinkButtonProps;
    subtitle?: string | null;
    title?: string;
}

const Error404Content = ({
    image,
    linkButton,
    subtitle = 'The page could not be found.',
    title = '404'
}: Error404ContentProps) => (
    <div className={styles.container}>
        {title && <h1 className={styles.title}>{title}</h1>}
        <div
            className={
                image && (linkButton || subtitle) ? styles.col2 : styles.col1
            }
        >
            {image && (
                <div>
                    <ResponsiveImage {...image} />
                </div>
            )}
            <div className={styles['flex-col']}>
                {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
                {linkButton && (
                    <BrandedLinkButton
                        className={styles.linkButton}
                        {...linkButton}
                        href={linkButton.url}
                    >
                        {linkButton.label}
                    </BrandedLinkButton>
                )}
            </div>
        </div>
    </div>
);

const Error404Main = () => {
    const {
        content: {
            pageData: { contentGroupCollection: { items } = {} }
        }
    } = usePageContext();

    return (
        <main className={styles.main}>
            {items?.map((item, itemIndex) => {
                const { Image, Link } = item.entriesCollection?.items?.length
                    ? entriesByTypename(item.entriesCollection?.items)
                    : { Image: '', Link: '' };

                const { subtitle, title } = item;
                const image = Image[0];
                const link = Link[0];

                return (
                    <Error404Content
                        key={`${itemIndex}-${item.title}`}
                        linkButton={link}
                        image={image}
                        subtitle={subtitle}
                        title={title}
                    />
                );
            })}
        </main>
    );
};

export default Error404Main;
